let colors = {
    'hausastrid': '#A82D03',
    'suedstrand': '#3F7BA1',
    'hausnina': '#4688BE'
};

export const state = () => ({
    fewoDatas: {
        'suedstrand205-206': [
            {
                "logotitle": "Ferienwohnung Südstrand 205 - 206",
                "logo": "2020-01-27-logo-suedstrand.svg",
                "color": colors.suedstrand,
                "headline": "Ferienwohnungen mit Ostseeblick am Südstrand",
                "description": "Ferienwohnungen für 1-4 Personen am Südstrand der Insel Fehmarn an der Ostsee.",
                "headerimgMobile": "suedstrandwohnung205-206/buehne_768x768@2x.jpg",
                "headerimg": "suedstrandwohnung205-206/buehne_1000x350@2x.jpg",
                "qm": "49 m²",
                "price": "55,00 €",
                "footer": '<p>Besuchen Sie auch unsere Ferienwohnungen im <a href="https://www.hausnina.de/" target="_blank"><span class="icon-house"></span>Haus Nina</a> oder im <a href="https://www.hausastrid.de/" target="_blank"><span class="icon-house"></span>Haus Astrid</a> in Rostock/Warnemünde</p>',
                "fewos": [
                    {
                        "gallery": [
                            {
                                "caption": "Wohnzimmer",
                                "img": 'suedstrandwohnung205-206/gallery/fewo-205/04_205_wz.jpg',
                                "thumb": require('~/assets/img/suedstrandwohnung205-206/gallery/fewo-205/04_205_wz.jpg?resize').src,
                                "src": require('~/assets/img/suedstrandwohnung205-206/gallery/fewo-205/04_205_wz.jpg?resize').src
                            },
                            {
                                "caption": "Wohnzimmer",
                                "img": 'suedstrandwohnung205-206/gallery/fewo-205/05_205_wz.jpg',
                                "thumb": require('~/assets/img/suedstrandwohnung205-206/gallery/fewo-205/05_205_wz.jpg?resize').src,
                                "src": require('~/assets/img/suedstrandwohnung205-206/gallery/fewo-205/05_205_wz.jpg?resize').src
                            },
                            {
                                "caption": "Schlafzimmer",
                                "img": 'suedstrandwohnung205-206/gallery/fewo-205/03_205_sz.jpg',
                                "thumb": require('~/assets/img/suedstrandwohnung205-206/gallery/fewo-205/03_205_sz.jpg?resize').src,
                                "src": require('~/assets/img/suedstrandwohnung205-206/gallery/fewo-205/03_205_sz.jpg?resize').src
                            },
                            {
                                "caption": "Küche",
                                "img": 'suedstrandwohnung205-206/gallery/fewo-205/02_205_kueche.jpg',
                                "thumb": require('~/assets/img/suedstrandwohnung205-206/gallery/fewo-205/02_205_kueche.jpg?resize').src,
                                "src": require('~/assets/img/suedstrandwohnung205-206/gallery/fewo-205/02_205_kueche.jpg?resize').src
                            },
                            {
                                "caption": "Bad",
                                "img": 'suedstrandwohnung205-206/gallery/fewo-205/01_205_bad.jpg',
                                "thumb": require('~/assets/img/suedstrandwohnung205-206/gallery/fewo-205/01_205_bad.jpg?resize').src,
                                "src": require('~/assets/img/suedstrandwohnung205-206/gallery/fewo-205/01_205_bad.jpg?resize').src
                            },
                            {
                                "caption": "Grundriss",
                                "img": 'suedstrandwohnung205-206/gallery/fewo-205/03_205.jpg',
                                "thumb": require('~/assets/img/suedstrandwohnung205-206/gallery/fewo-205/03_205.jpg?resize').src,
                                "src": require('~/assets/img/suedstrandwohnung205-206/gallery/fewo-205/03_205.jpg?resize').src
                            }
                        ],
                        "title": "Wohnung Nr. 205",
                        "description": "<p>Die Ferienwohnung verfügt über 49m² und bietet Platz für 1-4 Personen. Neben dem Wohnzimmer mit Doppelbett und dem Schlafzimmer mit 2 Einzelbetten gehört eine Küche sowie das Bad mit Dusche zur Grundausstattung. Highlight ist der etwa 5 m² große Balkon mit Blick auf die Ostsee. Bei Bedarf steht Ihnen zusätzlich ein Babybett sowie ein Hochstuhl zur Verfügung.</p>" +
                            "                    <p><strong>Ausstattung:</strong><br/>" +
                            "                        Geschirrspüler, Kabelfernsehen, Radio, Balkon, Fahrstuhl, Parkplatz, Tiere erlaubt, WLan vorhanden. Handtücher und Bettwäsche können bei der Schlüsselübergabe kostenpflichtig\n" +
                            "gemietet werden." +
                            "                    </p>",
                        "price": "55,00 €",
                        "qm": "49 m²",
                        "person": "max. 4",
                        "priceNight": "55,00 - 95,00 €",
                        "dog": "10,00 €",
                        "cleaning": "75,00 €"
                    },
                    {
                        "gallery": [
                            {
                                "caption": "Wohnzimmer",
                                "img": 'suedstrandwohnung205-206/gallery/fewo-206/03_206_wz.jpg',
                                "thumb": require('~/assets/img/suedstrandwohnung205-206/gallery/fewo-206/03_206_wz.jpg?resize').src,
                                "src": require('~/assets/img/suedstrandwohnung205-206/gallery/fewo-206/03_206_wz.jpg?resize').src
                            },
                            {
                                "caption": "Wohnzimmer",
                                "img": 'suedstrandwohnung205-206/gallery/fewo-206/04_206_wz.jpg',
                                "thumb": require('~/assets/img/suedstrandwohnung205-206/gallery/fewo-206/04_206_wz.jpg?resize').src,
                                "src": require('~/assets/img/suedstrandwohnung205-206/gallery/fewo-206/04_206_wz.jpg?resize').src
                            },
                            {
                                "caption": "Schlafzimmer",
                                "img": 'suedstrandwohnung205-206/gallery/fewo-206/02_206_sz.jpg',
                                "thumb": require('~/assets/img/suedstrandwohnung205-206/gallery/fewo-206/02_206_sz.jpg?resize').src,
                                "src": require('~/assets/img/suedstrandwohnung205-206/gallery/fewo-206/02_206_sz.jpg?resize').src
                            },
                            {
                                "caption": "Bad",
                                "img": 'suedstrandwohnung205-206/gallery/fewo-206/01_206_bad.jpg',
                                "thumb": require('~/assets/img/suedstrandwohnung205-206/gallery/fewo-206/01_206_bad.jpg?resize').src,
                                "src": require('~/assets/img/suedstrandwohnung205-206/gallery/fewo-206/01_206_bad.jpg?resize').src
                            },
                            {
                                "caption": "Grundriss",
                                "img": 'suedstrandwohnung205-206/gallery/fewo-206/01_206.jpg',
                                "thumb": require('~/assets/img/suedstrandwohnung205-206/gallery/fewo-206/01_206.jpg?resize').src,
                                "src": require('~/assets/img/suedstrandwohnung205-206/gallery/fewo-206/01_206.jpg?resize').src
                            }
                        ],
                        "title": "Wohnung Nr. 206",
                        "description": "<p>Die Ferienwohnung hat eine Größe von 64 m² und verfügt über Wohnzimmer, Schlafzimmer mit Doppelbett, gut ausgestattete Küche, 2 Bäder, großem Balkon mit Blick auf die Ostsee. Das Wohnzimmer ist mit einem Doppelklappbett und einer Couch ausgestattet. Sie bietet Platz für maximal 4 Personen. Ein Babybett und ein Hochstuhl können bereitgestellt werden (auf Anfrage).</p>" +
                            "                    <p><strong>Ausstattung:</strong><br/>\n" +
                            "                        Geschirrspüler, Kabelfernsehen, Radio, Balkon, Fahrstuhl, Parkplatz, Tiere erlaubt, WLan  vorhanden. Handtücher und Bettwäsche können bei der Schlüsselübergabe kostenpflichtig\n" +
                            "gemietet werden.</p>",
                        "price": "60,00 €",
                        "qm": "64 m²",
                        "person": "max. 4",
                        "priceNight": "60,00 - 105,00 €",
                        "dog": "10,00 €",
                        "cleaning": "85,00 €"
                    },
                ],
                "seasons": [
                  {
                    "title": "Nebensaison",
                    "season": [
                      {
                        "time": "01.01.24 - 22.03.24",
                      },
                      {
                        "time": "04.11.24 - 19.12.24",
                      }
                    ],
                  },
                  {
                    "title": "Zwischensaison",
                    "season": [
                      {
                        "time": "22.03.24 - 15.06.24",
                      },
                      {
                        "time": '15.09.24 - 03.11.24'
                      },
                      {
                        "time": '20.12.24 - 05.01.25'
                      }
                    ],
                  },
                  {
                    "title": "Hauptsaison",
                    "season": [
                      {
                        "time": "15.06.24 - 15.09.24",
                      }
                    ],
                  }
                ],
                "priceinfo": "<p>Die genannten Preise beziehen sich auf eine Übernachtung pro Ferienwohnung. Im Mietpreis enthalten sind Strom und Wasser.</p>",
                "locationinfo": "Planen Sie Ihre Anreise mit Google Maps. <br/> Wir wünschen Ihnen eine gute Fahrt.",
                "address": "<p>Strandhaferweg 3<br/>23769 Fehmarn<br/>Deutschland</p>",
                "mapslink": "https://www.google.de/maps/dir//Strandhaferweg+3,+23769+Fehmarn/@54.4112689,11.1250182,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x47ad7b1b7e60aff1:0x34ffc350cab5f6e4!2m2!1d11.1952291!2d54.4112893",
                "maps": "suedstrandwohnung204/map-suedstrand.jpg",
                "contactAddress": "<p>Heinz-Georg Schäfer<br/>\n" +
                    "                        Edelhofdamm 6<br/>\n" +
                    "                        13465 Berlin<br/>\n<br/>\nTel: + 49 (0) 30 401 63 84 <br/>\n" +
                    "                        Fax: + 49 (0) 30 401 02 113<br/>\n<br/>\n</p>",
                "contactEMail": "kontakt@suedstrand-wohnung.de",
                "contactHeadline": "Buchen Sie Ihre Ferienwohnung am Südstrand",
                "contactInfos": [
                    {
                        "icon": "icon-direkter-Blick-auf-die-Ostsee.svg",
                        "title": "Direkter Blick auf die Ostsee"
                    },
                    {
                        "icon": "icon-raum-zu-freien-entfaltung.svg",
                        "title": "Ideal für Familien"
                    },
                    {
                        "icon": "icon-private.svg",
                        "title": "Privater Anbieter"
                    },
                    {
                        "icon": "icon-keine-versteckten-Kosten.svg",
                        "title": "Keine versteckten Kosten"
                    }
                ]
            }
        ]
    }
})
